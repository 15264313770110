import flatPicker from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import 'flatpickr/dist/l10n/ko.js'

// https://chmln.github.io/flatpickr/options/
const commFlatpickrMixin = {
  data () {
    return {
      flatpickrConfig: {
        locale: 'ko',
        wrap: false,
        dateFormat: 'Y-m-d',
        altInput: false,
        isShowSvg: true, // 이전월, 다음월 이동 화살표 출력 여부
        isEnableNumInput: true, // 년도 입력박스 활성화 여부
      },
      rangeFlatpickrConfig: {
        mode: 'range',
        locale: 'ko',
        wrap: false,
        dateFormat: 'Y-m-d',
        altInput: false,
        isShowSvg: true, // 이전월, 다음월 이동 화살표 출력 여부
        isEnableNumInput: true, // 년도 입력박스 활성화 여부
      }
    }
  },
  components: {
    flatPicker
  }
}

export default commFlatpickrMixin
